import { store } from "../Redux";
import { callGetUserName } from "../Redux/slices/sendMoneySlice";
import { createUserInfoPayload } from "../config/commonPayloads";
import { RECENT_CONTACTS_TIMEOUT } from "../data/constants";
import { parseMsisdn, getPhoneNumberFromLs } from "../utils/commonUtils";
import { checkValidTimeDifference } from "./commonHelpers";
import { getText } from "./translateHelper";
import { SOME_ERR } from "../data/constants";
import { ToastNotifySuccess, ToastNotifyError } from "../components/ToastNotify";
import { callFetchTranslations } from "../Redux/slices/translationSlice";
import { callGetUserInfo, callSetUserPreferences, callGetAMUserProfile } from "../Redux/slices/homeSlice";

/**
 * Return name if present in cache, else get async'ly using callback
 * @param msisdn 
 * @param callback 
 * @param errorCallback 
 * @returns cached name
 */
export const getUserName = (msisdn: string, callback: (name: string) => void, errorCallback: () => void) => {
  const backendCompatNum = parseMsisdn(msisdn)

  const nameCache = store?.getState()?.globalStore?.recentNumberName?.[backendCompatNum];

  const cacheIsStale = !nameCache?.name || checkValidTimeDifference(nameCache?.timeStamp, RECENT_CONTACTS_TIMEOUT);
  if (cacheIsStale){
    store?.dispatch(callGetUserName(createUserInfoPayload(backendCompatNum, callback, errorCallback)));
    return null;
  }
  else
    return nameCache?.name;
}

export const updateUserConfig = (lang: string, successCallback = () => {}, saveToHomeDNDEnabled=false, showToast=true) => {
  const phoneNumber = getPhoneNumberFromLs();
  const isEnabled = saveToHomeDNDEnabled || store.getState().home.userInfo.userConfig.saveToHomeDNDEnabled;
  const userPrefPayload = {
    data: {
      "msisdn": phoneNumber,
      "userLanguage": lang ? lang : undefined,
      "saveToHomeDNDEnabled": isEnabled
    },
    successCallback: function () {
      const userInfoPayload = {
        data: { msisdn: phoneNumber },
        callback: function () {
          store.dispatch(callFetchTranslations({ 
            progressCallback: () => { },
            successCallback: () => {
              showToast && ToastNotifySuccess(getText("LANGUAGE_PREFERENCE_SAVED"));
              if(typeof successCallback === "function") successCallback();
            },
            errorCallback: () => { }
          }))
        }
      }
      store.dispatch(callGetUserInfo(userInfoPayload));
    },
    errorCallback: () => {
      ToastNotifyError(getText(SOME_ERR));
    }
  }
  store.dispatch(callSetUserPreferences(userPrefPayload));
}

export function callAmUserProfile() {
  let amUserProfilePayload = {
    data: { msisdn: getPhoneNumberFromLs() },
  };
  store?.dispatch(callGetAMUserProfile(amUserProfilePayload));
}