import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { RootState } from "../../Redux";
import { setOnline } from "../../Redux/slices/globalSlice";
import Loader from "../../components/Loader";
import BlockWebAccess from "../../features/BlockWebAccess";
import useBackgroundBalanceFetch from "../../hooks/useBackgroundBalanceFetch";
import { useOnlineStatus } from "../../hooks/useOnlineStatus";
import { checkMinimumScreenSize, checkValidBrowser, deleteAuthToeknFromLs, getAuthTokenFromLs, getIsGuestUser } from "../../utils/commonUtils";

import blockIcon from "../../assets/icons/ic_ban.svg";
import { getText } from "../../helpers/translateHelper";
import { useTranslations } from "../../hooks/useTranslations";
import { SOME_ERR, V1, V2 } from "../../data/constants";
import useLockScrollOnInputFocus from "../../hooks/useLockScrollOnInputFocus";
import useDelayedFunction from "../../hooks/useDelayedFunction";
import { callFetchTransactionsFailed, setMaskedAmBalance, setMaskedAmTransaction } from "../../Redux/slices/amSlice";
import { callLogout } from "../../Redux/slices/appSettingsSlice";
import { useIdle } from "../../hooks/useIdleTimer";
import icons from "../../config/icons";
import { APP_SETTINGS_ROUTE, HE_Error, HOME_PAGE, IN_COMPATIBLE, LOGIN_ROUTE, LOG_OUT, SUCCESS_V2 } from "../../routes/routeConstants";
import { Skeleton } from "../../components/SkeletonCard";
import { getSkeletonBlock } from "../../routes/routeSkeletons";
import ErrorBoundaryUI from "../../components/ErrorBoundary/ErrorBoundaryUI";
import { callFetchTranslations } from "../../Redux/slices/translationSlice";
import { callLaunchConfigApi } from "../../Redux/slices/configSlice";
import ErrorBoundary from "../../components/ErrorBoundary";
import RenderIosAthsScreen from "../../components/ShowIOSAuthScreen";
import AddToHomePopup from "../../components/AddToHomePopUp";
import { updateAddToHomePopUp } from "../../Redux/slices/globalSlice";

interface Props {
  children: any;
}
type CloseMethodKeys = "/home/app-settings" | "/success-v2";

const HomeLayout: React.FC<Props> = (props) => {
  const isLoading = useSelector((state: RootState) => state.globalStore?.isLoading),
    isCustomLoading = useSelector((state: RootState) => state.globalStore?.isCustomLoading),
    isAmMasked = useSelector((state: RootState) => state?.balance?.balance?.isAmMasked),
    amUnmaskedTimeStamp = useSelector((state: RootState) => state.amStore.amUnmaskedTimeStamp),
    isAMTranscationMasked = useSelector((state: RootState) => state.amStore.isAMTranscationMasked),
    amTransactionTimestamp = useSelector((state: RootState) => state.amStore.amTransactionTimestamp),
    launchConfig = useSelector((state: RootState) => state.config.launchConfig) || {},
    apiError = useSelector((state: RootState) => state.globalStore.apiError),
    version = launchConfig.version,
    sessionTimeout = launchConfig.sessionTimeout,
    autoLogout = launchConfig.autoLogout,
    navigate = useNavigate(),
    dispatch = useDispatch(),
    isOnline = useOnlineStatus(),
    location = useLocation(),
    backClickCount = useRef(0),
    backgroundFetch = useBackgroundBalanceFetch(),
    { translationsAreInit, translationError } = useTranslations(),
    openAddToHomePopUp = useSelector((state: RootState) => state.globalStore?.openAddToHomePopUp),
    windowObj: any = window;
  let timerId: any = null;

  const CLOSE_METHODS: Record<CloseMethodKeys, () => void> = {
    [APP_SETTINGS_ROUTE]: () => {
      deleteAuthToeknFromLs()
      navigate(LOG_OUT, { replace: true });
    },
    [SUCCESS_V2]: () => navigate(HOME_PAGE),
  }

  useLockScrollOnInputFocus();
  useEffect(() => {
    if (version === V1) {
      document.body.style.backgroundColor = `#212121`
    }
    else {
      document.body.style.backgroundColor = `#FAFAFA`
      document.addEventListener("visibilitychange", function () {
        if (document.hidden) {
          if (getAuthTokenFromLs() && process.env.NODE_ENV === "production" && autoLogout) {
            dispatch(callLogout({
              hideLoader: true,
              callback: () => {
                navigate(LOG_OUT, { replace: true });
              }
            }))
          }
        }
      }, false);
    }
    return (() => {
      if (timerId) {
        clearInterval(timerId);
      }
      document.removeEventListener("visibilitychange", function () { });
    })
  }, []);


  // useEffect(() => {
  //   if (!getAuthTokenFromLs() && !noAuthCheckRoutes.includes(location.pathname)) {
  //     resetData();
  //     navigate(LOG_OUT)
  //   }
  // }, [getAuthTokenFromLs(), location])

  useEffect(() => {
    if (!!windowObj?.navigator?.onLine) {
      dispatch(setOnline(true));
    }
  }, [windowObj.navigator.onLine])

  useEffect(() => {
    if (!isAmMasked) {
    }
  }, [isAmMasked])

  const handleIdle = () => {
    if (window.location.pathname !== "/" && getAuthTokenFromLs()) {
      dispatch(callLogout({
        callback: () => {
          navigate(LOG_OUT, { replace: true });
        }
      }))
    }
  };

  function refreshFunc() {
    if (apiError?.errorBy?.includes("launch-config"))
      dispatch(callLaunchConfigApi({}));
    else
      dispatch(callFetchTranslations({}));
  }

  useDelayedFunction(1000 * 60 * 5, amUnmaskedTimeStamp, () => dispatch(setMaskedAmBalance(true)), isAmMasked);
  useDelayedFunction(1000 * 60 * 5, amTransactionTimestamp, () => { dispatch(setMaskedAmTransaction(true)); dispatch(callFetchTransactionsFailed({})) }, isAMTranscationMasked);
  useIdle({ onIdle: handleIdle });

  const handleClose = () => {
    return CLOSE_METHODS[location.pathname as CloseMethodKeys] ?? (() => { })
  }

  return (
    <div style={{ backgroundColor: "#FAFAFA" }} className={`layout-container w-[100vw] ${version === V2 ? "bg-[#FAFAFA]  h-full" : "overflow-y-hidden bg-primaryBlack h-[100svh]"}`}>
      {translationsAreInit ? <>
        <BlockWebAccess />
        {props.children}
        <div className="block-landscape hidden bg-black w-[100vw] h-[100vh] fixed top-0 bottom-0 right-0 left-0">
          <div className="landscape-text text-white font-bold flex justify-center items-center h-full text-center flex-col">
            <div className="icon mb-30">
              <img src={blockIcon} alt="block-icon" />
            </div>
            {getText("LANDSCAPE_MODE_MESSAGE1")}<br /> {getText("LANDSCAPE_MODE_MESSAGE2")}
          </div>
        </div>
      </>
        :
        <Skeleton getskeletonLogin={true} />
      }
      {!!apiError?.isApiError &&
        < ErrorBoundaryUI refreshFunc={refreshFunc} />
      }
      {isLoading ?
        <Loader />
        :
        null
      }
      <AddToHomePopup isOpen={openAddToHomePopUp} onClose={handleClose()} from={location.pathname} />
      <RenderIosAthsScreen />
    </div>
  )
}

export default HomeLayout;