import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { clearStore } from "../actions";
import { AM_NO_WALLET_CODE, AM_DOWN_CODE } from "../../data/constants";

interface IUserInfoResponse {
  customerAddress: {
    city: string;
    addressLine1: string;
  };
  firstName: string;
  lastName: string;
  emailVerified: boolean;
  customerType: string;
  emailUpdateEnabled: boolean;
  lineType: string;
  simSerialNumber: string;
  msisdn: string;
  customerName: string;
  otpTimer: number;
  timeStamp: string;
  userConfig: {
    userLanguage: string;
    saveToHomeDNDEnabled : boolean;
    firstLogin : boolean;
  };
}
export interface HomeSliceProps {
  userInfoData: {
    customerName: string;
    timeStamp: string;
  };
  amProfile: {
    accountStatus: {
      accountStatus: boolean;
      transactionAllowed: boolean;
      pinSet: string;
      recoveryQuestionSet: string;
      pinReset: string;
      isActive?: boolean;
      // barredDetails: null;
    };
    firstName: string;
    lastName: string;
    dob: string; //yyyy-mm-dd hh:mm:ss.s
    businessName: string;
    userType: string;
    userId: string;
    userBarred: boolean;
    idDetails: {
      idType: string;
      idNumber: string;
    };
    timeStamp: string;
    isAMProfileFetched: boolean;
    recoveryQuestionSet: boolean;
    isLowBalanceUser: boolean;
    isAmServiceAvailable: boolean
  };
}

const initialState = {
  userInfo: {
    customerName: "",
    timeStamp: "",
    userConfig: {
      userLanguage: "",
      saveToHomeDNDEnabled : false,
      firstLogin : false
    }
  },
  amProfile: {
    accountStatus: {
      accountStatus: undefined,
      transactionAllowed: undefined,
      pinSet: undefined,
      pinReset: undefined,
      recoveryQuestionSet: undefined,
      barredDetails: undefined,
      isActive: false
    },
    firstName: undefined,
    lastName: undefined,
    dob: undefined,
    businessName: undefined,
    userType: undefined,
    userId: undefined,
    userBarred: undefined,
    barredDetails: null as any,
    blockedByInvalidPin: undefined,
    lockInfo: {
      lockExpiry: "",
      lockedBy: "",
      isLocked: false
    },
    idDetails: {
      idType: "",
      idNumber: "",
    },
    timeStamp: "",
    isFetchingAMProfile: false,
    recoveryQuestionSet: false,
    isLowBalanceUser: false,
    isAmServiceAvailable: true,
  },
  staticPage: {},
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    //user info api
    callGetUserInfo: (state, action) => { },
    callGetUserInfoSuccess: (state, action: { payload: { result: IUserInfoResponse } }) => {
      if (!state.userInfo) {
        state.userInfo = { ...initialState.userInfo };
      }
      state.userInfo.timeStamp = moment().format();
      state.userInfo.customerName = action.payload?.result?.customerName;
      state.userInfo.userConfig = action.payload?.result?.userConfig;
    },
    callGetUserInfoFailed: (state, action) => { },

    //am user profile api
    callGetAMUserProfile: (state, action) => {
      state.amProfile.isFetchingAMProfile = true;
    },
    callGetAMUserProfileSuccess: (state, action) => {
      let result = { ...action.payload.result };
      result["timeStamp"] = moment().format();
      state.amProfile = result;
      state.amProfile.isFetchingAMProfile = false;
      state.amProfile.isAmServiceAvailable = true
    },
    callGetAMUserProfileFailed: (state, action) => {
      if (action.payload?.data?.statusCode === AM_NO_WALLET_CODE) {
        state.amProfile.accountStatus.isActive = false;
      }
      if (action.payload?.data?.statusCode === AM_DOWN_CODE) {
        state.amProfile.isAmServiceAvailable = false
      }
      state.amProfile.isFetchingAMProfile = false;
    },
    callGetIncentive: (state, action) => { },
    callSetUserPreferences: (state, action) => { },
  },
  extraReducers: (builder) => {
    builder //clear home store
      .addCase(clearStore, (state) => {
        return initialState;
      })
      .addDefaultCase((state, action) => { })
  }
});

export const {
  callGetUserInfo,
  callGetUserInfoFailed,
  callGetUserInfoSuccess,
  callGetAMUserProfile,
  callGetAMUserProfileFailed,
  callGetAMUserProfileSuccess,
  callGetIncentive,
  callSetUserPreferences,
} = homeSlice.actions;
export default homeSlice.reducer;
